import { FunctionComponent, memo } from 'react';
import { useTimer } from '~components/hooks';
import { useTranslation } from 'react-i18next';
import { Case as CaseType } from '~types';

interface FormatDateProps {
  getCase: CaseType;
  className?: string;
}

const FormatDate: FunctionComponent<FormatDateProps> = ({
  className = 'timer',
  getCase: { getDateEnd },
}) => {
  const { t } = useTranslation();
  const timer = useTimer(getDateEnd);

  if (!timer) {
    return null;
  }

  return (
    <div className={className}>
      {/* <div className="icon material-icons">schedule</div> */}
      <span className="day">
        {timer?.days} {t('d')}.
      </span>
      <span>
        {timer.hours} : {timer.minutes} : {timer.seconds}
      </span>
    </div>
  );
};

export default memo(FormatDate);
