import {
  memo,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Category as CategoryType, CategoryInputWhere } from '~types';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { CaseBlock } from './CaseBlock';
import { useGetCategories } from '../hooks';
import { Loader } from '../../Loader';
import './caseList.scss';
import './caseContainer.scss';
import { useConfig, useProfile } from '~frontend/components/hooks';
import LoaderNew from '../loaderNew/loaderNew';

interface CategoryProps {
  getCategory: CategoryType;
  isBattlePassCoins?: boolean;
  isMobile?: any;
  isRecommended?: any;
}

export const Category: FunctionComponent<CategoryProps> = memo(
  ({
    getCategory,
    isBattlePassCoins,
    isMobile,
    isRecommended,
  }: CategoryProps) => (
    <article
      className={`case_list_wrap`}
      // data-rar={getCategory?.color}
      style={{
        // background:
        //   'linear-gradient(180deg, rgb(31, 31, 39) 0%, rgba(18,0,31,1) 35%, rgba(2,0,36,1) 100%)', // Gradient background
        boxShadow: 'none',
        gap: isMobile ? '16px' : '24px',
      }}
      data-id={getCategory.id}
    >
      {!isRecommended && (
        <div className="case_list_wrap_title">{getCategory?.getName}</div>
      )}
      {/* <div className="case-row-title">
        <div
          className="case-row-title-name"
          style={{ color: getCategory.color }}
        >
          <span className="mask"></span>
          <span className="name">{getCategory.getName}</span>
        </div>
        {getCategory.getDescription && (
          <div className="case-row-title-sec">{getCategory.getDescription}</div>
        )}
      </div> */}
      <div className="case_list">
        {_.map(getCategory?.getCases, (getCase) => (
          <CaseBlock
            getCase={getCase}
            key={getCase?.id}
            isBattlePassCoins={isBattlePassCoins}
          />
        ))}
      </div>
    </article>
  ),
);

Category.displayName = 'Category';

interface ICategoriesView {
  where?: any;
  className?: string | undefined;
  filters?: any; // Передаем фильтры из Home
  onCategoriesLoaded?: any;
  isRecomended?: boolean;
}

export const CategoriesView: FunctionComponent<ICategoriesView> = memo(
  ({
    where,
    className = 'cases-all',
    filters,
    onCategoriesLoaded,
    isRecomended,
  }) => {
    const { loading, error, data, fetchMore, refetch } =
      useGetCategories(where);

    // // Фильтрация данных в зависимости от isRecomended
    // const filtered = isRecomended
    //   ? data?.getCategories?.getCategories.filter(
    //       (cat) => cat.getName === 'Recommended',
    //     )
    //   : data?.getCategories?.getCategories;

    const [originalCategories, setOriginalCategories] = useState<
      CategoryType[]
    >([]);
    const [filteredCategories, setFilteredCategories] = useState<
      CategoryType[]
    >([]);
    const [attempts, setAttempts] = useState(0);
    const MAX_ATTEMPTS = 3; // Максимальное количество попыток

    const getProfile = useProfile();
    const getConfig = useConfig();

    const isMobile =
      getConfig?.userAgent.match(
        /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
      ) ?? false;

    useEffect(() => {
      if (data?.getCategories?.getCategories) {
        let categories = data.getCategories.getCategories;

        // Если isRecomended == true, оставляем только категорию "Recommended"
        if (isRecomended) {
          categories = categories.filter(
            (cat) => cat.getName === 'Recommended',
          );
        } else {
          categories = categories.filter(
            (cat) => cat.getName !== 'Recommended',
          );
        }

        setOriginalCategories(categories);
        setFilteredCategories(categories);

        if (onCategoriesLoaded) {
          onCategoriesLoaded(
            categories.map((c) => ({ value: c.id, label: c.getName })),
          );
        }
      }
    }, [data, isRecomended, onCategoriesLoaded]);

    let getCategories = data?.getCategories?.getCategories;
    //console.log('getCategories', getCategories);
    const count = data?.getCategories?.count;
    const currentLength = _.size(getCategories);
    if (!_.isEmpty(getCategories) && attempts < 1) {
      //console.log('delete cats');
      //getCategories = {} as any;
    }
    // useEffect(() => {
    //   if (
    //     !loading &&
    //     !error &&
    //     _.isEmpty(getCategories) &&
    //     attempts < MAX_ATTEMPTS
    //   ) {
    //     console.log('refetch');
    //     const timer = setTimeout(() => {
    //       setAttempts(attempts + 1);
    //       refetch();
    //     }, 2000); // Повторный запрос через 2 секунды

    //     return () => clearTimeout(timer);
    //   }
    // }, [loading, error, getCategories, attempts, refetch]);

    const onLoadMore = useCallback(async () => {
      try {
        await fetchMore({
          variables: {
            input: {
              offset: isRecomended ? currentLength : filteredCategories.length,
              limit: 2,
            },
          },
        });
      } catch (error) {
        console.error('Error fetching more categories:', error);
      }
    }, [filteredCategories.length, fetchMore, isRecomended]);

    // useEffect(() => {
    //   if (data?.getCategories?.getCategories) {
    //     const categories = data.getCategories.getCategories;
    //     setOriginalCategories(categories);
    //     setFilteredCategories(categories);

    //     //Пробрасываем категории наверх в Home
    //     onCategoriesLoaded(
    //       categories.map((c) => ({
    //         value: c.id,
    //         label: c.getName,
    //       })),
    //     );
    //   }
    // }, [data, onCategoriesLoaded]);

    // Логика фильтрации и сортировки на клиенте
    const applyFilters = useCallback(() => {
      if (!originalCategories.length) return;

      //Если это Recommended, не применяем фильтры
      if (isRecomended) {
        setFilteredCategories(originalCategories);
        return;
      }

      let updatedCategories = [...originalCategories];

      updatedCategories = updatedCategories.map((category) => ({
        ...category,
        getCases: category.getCases
          .filter((caseItem) => {
            if (
              filters.category !== 'All' &&
              caseItem.category !== filters.category
            ) {
              return false;
            }
            if (
              filters.minPrice !== undefined &&
              filters.minPrice > 0 &&
              caseItem.price < filters.minPrice * 100
            ) {
              return false;
            }
            if (
              filters.maxPrice !== undefined &&
              filters.maxPrice > 0 &&
              caseItem.price > filters.maxPrice * 100
            ) {
              return false;
            }
            if (
              filters.enoughBalance !== undefined &&
              filters.enoughBalance &&
              getProfile &&
              caseItem.price > getProfile?.money
            ) {
              return false;
            }
            return true;
          })
          .sort((a, b) => {
            if (filters.sortOrder === 'Sort') {
              return 0;
            } else if (filters.sortOrder === 'Lowest price first') {
              return a.price - b.price;
            } else {
              return b.price - a.price;
            }
          }),
      }));

      setFilteredCategories(updatedCategories);
    }, [filters, originalCategories, getProfile, isRecomended]);

    useEffect(() => {
      applyFilters();
    }, [filters, originalCategories, isRecomended]);

    if (loading) {
      return <LoaderNew />;
    } else if (error) {
      return <>GetCategories | Error! {error.message}</>;
    } else if (!getCategories || getCategories.length === 0) {
      return <>No categories found.</>;
    }

    return (
      <section className={`main_cases_list ${className}`}>
        {/* Убираем InfiniteScroll и блок с тайтлом категории, если isRecomended */}
        {isRecomended ? (
          <div className="row">
            {filteredCategories.map((getCategory) => {
              if (!_.isEmpty(getCategory.getCases))
                return (
                  <Category
                    isRecommended={isRecomended}
                    key={getCategory.id}
                    getCategory={getCategory}
                    isMobile={isMobile}
                  />
                );
            })}
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={currentLength < count}
            loader={<LoaderNew key={0} />}
            className="row"
          >
            {filteredCategories.map((getCategory) => {
              if (!_.isEmpty(getCategory.getCases))
                return (
                  <Category
                    key={getCategory.id}
                    getCategory={getCategory}
                    isRecommended={isRecomended}
                    isMobile={isMobile}
                  />
                );
            })}
          </InfiniteScroll>
        )}
      </section>
    );
  },
);
